import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

import emailSrc from "./email.svg";
import phoneSrc from "./phone.svg";

import { useOnboardingInitialInformation } from "@app/components/signed-in-layout/use-onboarding-initial-information";
import { paths } from "@app/constants/paths";
import { useAccountManager } from "@app/hooks/use-account-manager";
import { useClients } from "@app/hooks/use-clients";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

export const InboundCapturedModal = () => {
	const [showGetStartedModal, setShowGetStartedModal] = useState(true);
	const [showHelpModal, setShowHelpModal] = useState(false);
	const isMobile = useMediaQuery();
	const navigate = useNavigate();

	const { activeClientId } = useClients();
	const { data: accountManager } = useAccountManager(activeClientId);

	const { data } = useOnboardingInitialInformation();

	const isIndividual = !data?.name;

	const email = accountManager?.email ?? "info@futureforex.com";
	const phone = accountManager?.contact_number ?? "021 518 0558";

	return (
		<>
			<Dialog
				size="sm"
				bottomsheet={isMobile}
				isOpen={showGetStartedModal}
				title={isMobile ? "Thank you" : "Get started"}
				classNameDescription={styles.description}
				description={
					!data ? undefined : isIndividual ? (
						<>
							Thank you. A member of our team will be in touch with you on{" "}
							<strong>{data.contact_number}</strong> shortly.
						</>
					) : (
						<>
							Thank you. A member of our team will be in touch with{" "}
							<strong>
								{data.contact_person_first_name} {data.contact_person_last_name}
							</strong>{" "}
							on <strong>{data.contact_number}</strong> shortly.
						</>
					)
				}
				actions={
					<Button
						block={isMobile}
						centered
						onClick={() => {
							if (isIndividual) {
								navigate(paths().onboarding.individual.personalInformation);
							} else {
								navigate(paths().onboarding.business.companyBasics);
							}
						}}
					>
						Continue
					</Button>
				}
			>
				<p className={styles.description}>
					In the meantime, you are welcome to proceed with your account setup.
				</p>
				<Button
					type="button"
					variant="tertiary"
					className={styles.button}
					inline
					onClick={() => {
						setShowHelpModal(true);
						setShowGetStartedModal(false);
					}}
				>
					Need help?
				</Button>
			</Dialog>
			<Dialog
				size="sm"
				bottomsheet={isMobile}
				title="Need help?"
				isOpen={showHelpModal}
				description="A member of our team will be in touch shortly."
				classNameDescription={styles.description}
				actions={
					<Button
						centered
						onClick={() => {
							setShowGetStartedModal(true);
							setShowHelpModal(false);
						}}
					>
						Back
					</Button>
				}
			>
				<p className={styles.description}>
					If you would prefer to contact us directly,
					<br /> you can do so at:
				</p>
				{isMobile ? (
					<div className={styles.buttonGroup}>
						<a
							className={styles.linkButton}
							href={`mailto:${email}`}
							rel="noreferrer"
							target="_blank"
						>
							<img src={emailSrc} alt="" width={20} height={20} />
							Email
						</a>

						<a
							className={styles.linkButton}
							href={`tel:${phone}`}
							rel="noreferrer"
							target="_blank"
						>
							<img src={phoneSrc} alt="" width={20} height={20} />
							Phone
						</a>
					</div>
				) : (
					<ul className={styles.contactContainer}>
						<li className={styles.contactItem}>
							<img src={emailSrc} alt="" width={16} height={16} />
							<a href={`mailto:${email}`} rel="noreferrer" target="_blank">
								{email}
							</a>
						</li>
						<li className={styles.contactItem}>
							<img src={phoneSrc} alt="" width={16} height={16} />
							<a href={`tel:${phone}`} rel="noreferrer" target="_blank">
								{phone}
							</a>
						</li>
					</ul>
				)}
			</Dialog>
		</>
	);
};
